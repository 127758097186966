<template>
  <survey-patient-form-modal @after-save="onAfterSaveSurveyPatientForm" :survey="lastClickedSurvey" :survey-patient="currentSurveyPatient" v-model="showSurveyPatientForm"></survey-patient-form-modal>
  <survey-form-modal :survey="currentSurvey" v-model="showSurveyForm" :editable="rowEditable"></survey-form-modal>
  <el-dialog v-model="showSelectPatientForm">
    <h6>Welchen Probanden möchten Sie hinzufügen?</h6>
    <patient-search :set_current_patient="false" @on-select="onSelectPatient"></patient-search>
  </el-dialog>
  <el-dialog v-model="showCroData" width="50%" center>
    <h5>Ihre CRO Zugangsdaten</h5>
    <div v-if="currentCroData">
      <el-row class="mt-4">
        <el-col :span="4">
          Benutzername:
        </el-col>
        <el-col :span="4" :offset="1">
          {{currentCroData.username}}
        </el-col>
        <el-col :span="3" :offset="1">
          <el-button size="mini" type="warning" @click="copyCroUsername">Kopieren</el-button>
        </el-col>
      </el-row>
      <el-row class="mt-3">
        <el-col :span="4">
          Passwort
        </el-col>
        <el-col :span="4" :offset="1">
          ***********
        </el-col>
        <el-col :span="3" :offset="1">
          <el-button size="mini" type="warning" @click="copyCroPassword">Kopieren</el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>

  <div v-if="tableData">

    <el-table
        ref="surveyTable"
        :data="tableData"
        row-key="id"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowClick"
        @row-contextmenu="onRowRightClick"
        @expand-change="onRowExpand"
        stripe>
      <el-table-column v-if="rowExpandable" type="expand">
        <template #default="scope">
          <div class="text-right mb-2">
            <el-tooltip
              :disabled="scope.row.cro && scope.row.cro.ecrf_link"
              content="Bitte verknüpfen Sie diese Studie zunächst mit einer CRO die einen Link zum eCRF Portal besitzt"
              placement="bottom"
              effect="dark"
            >
              <el-button icon="el-icon-top-right" @click="openCroLink(scope.row)" :disabled="!scope.row.cro" size="mini">Zum Studienportal</el-button>
            </el-tooltip>
            <el-button @click="openSelectPatientForm(scope.row)" size="mini">Neuen Probanden hinzufügen</el-button>
          </div>
          <div v-if="!scope.row.patients.length">Keine Probanden zugeordnet</div>
          <div v-else class="customtable_wrapper expand-box mb-3 ml-3">

            <el-table
              ref="surveyTablePatients"
              :data="scope.row.patients"
              :default-sort = "{prop: 'patient.name', order: 'ascending'}"
              row-key="id"
              style="width: 100%"
              highlight-current-row
              @row-click="openSurveyPatientForm"
              >
                <el-table-column
                  prop="patient.name"
                  label="Name"
                  sortable>
                </el-table-column>
                <el-table-column
                  prop="patient.firstname"
                  label="Vorname"
                  sortable>
                </el-table-column>
                <el-table-column
                  label="Geburtsdatum">
                  <template #default="scope">
                    {{ $filters.germanDate(scope.row.patient.birthday) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="patient_survey_id"
                  label="Patienten-ID"
                  sortable>
                </el-table-column>
                <el-table-column
                  label="Datum EV"
                  sort-by="date_agreement"
                  sortable>
                  <template #default="scope">
                    {{ $filters.germanDate(scope.row.date_agreement) }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Arzt">
                </el-table-column>
                <el-table-column
                  label="Status"
                  sortable>
                  <template #default="scope">
                    {{(patientSurveyStateById(scope.row.status_id))}}
                  </template>
                </el-table-column>
            </el-table>

          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="title_short"
        label="Titel">
        <template #default="scope">
          {{scope.row.title_short}}
        </template>
      </el-table-column>
      <el-table-column v-if="showPivot">
        <template #header>
          <h6>Preis</h6>
        </template>
        <template #default="scope">
          <div v-if="scope.row.meta">
            <el-input class="text-right" v-model="scope.row.meta.pivot_Preis"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="status.title"
        label="Status">
        <template #header>
          <h6>Status</h6>
        </template>
      </el-table-column>
      <el-table-column
        label="Diagnose">
        <template #header v-if="showFilter">
          <h6>Diagnose</h6>
          <el-select v-model="filter.diagnosis" filterable>
            <el-option label="Keine Auswahl" :value="null"></el-option>
            <el-option
            v-for="item in selectableDiagnosis"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
          </el-select>
        </template>
        <template #default="scope">
          <div v-if="scope.row && scope.row.diagnosis">
            <div v-for="diagnosis in scope.row.diagnosis" :key="diagnosis.diagnosis_id">
              {{diagnosis.meta.diagnosis.title}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Behandlungssituation">
        <template #header v-if="showFilter">
          <h6>Behandlungssituation</h6>
          <el-select v-model="filter.treatmentsituation" filterable>
            <el-option label="Keine Auswahl" :value="null"></el-option>
            <el-option
              v-for="item in selectableTreatmentsituations"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>
        <template #default="scope">
          <div v-if="scope.row && scope.row.treatmentsituations">
            <div v-for="treatmentsituation in scope.row.treatmentsituations" :key="treatmentsituation.treatmentsituation_id">
              {{treatmentsituation.meta.treatmentsituation.title}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Biomarker">
        <template #header v-if="showFilter">
          <h6>Biomarker</h6>
          <el-select v-model="filter.biomarker" filterable>
            <el-option label="Keine Auswahl" :value="null"></el-option>
            <el-option
              v-for="item in selectableBiomarker"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>
        <template #default="scope">
          <div v-if="scope.row && scope.row.biomarker">
            <div v-for="biomarker in scope.row.biomarker" :key="biomarker.biomarker_id">
              {{biomarker.meta.biomarker.title}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="rowDeleteable"
        width="70">
        <template #default="scope">
          <div class="text-right">
            <el-popconfirm
              :title="'Sie möchten die Zuweisung zur Studie aufheben?'"
              confirmButtonText='Ja, aufheben'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import SurveyPatientFormModal from "../survey/SurveyPatientFormModal";
import SurveyFormModal from "../survey/SurveyFormModal";
import _ from "lodash";
import PatientSearch from "../PatientSearch";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SurveyTable",
  props: {
      data: Array,
      expandable: Boolean,
      clickable: Boolean,
      deleteable: Boolean,
      showfilter: Boolean,
      editable: Boolean,
      displaypivot: Boolean
  },
  emits: [
    'onRowDelete',
    'after-save'
  ],
  components: {
    PatientSearch,
    SurveyPatientFormModal,
    SurveyFormModal
  },
  data() {
    return {
      rowExpandable: this.expandable || false,
      rowClickable: this.clickable || false,
      rowDeleteable: this.deleteable || false,
      rowEditable: this.editable || false,
      showFilter: this.showfilter === undefined ? true : this.showfilter,
      showPivot: this.displaypivot || false,
      tableData: this.data,
      showSurveyPatientForm: false,
      showSurveyForm: false,
      showSelectPatientForm: false,
      showCroData: false,
      lastClickedSurvey:  null,
      currentSurveyPatient: null,
      currentSurvey: null,
      filter: {
        diagnosis: null,
        treatmentsituation: null,
        biomarker: null
      },
      currentTableRow: null,
      currentCroData: null
    }
  },
  mounted: function () {
  },
  computed: {
    ...mapGetters('surveys', ['patientSurveyStates', 'patientSurveyStateById']),
    selectableDiagnosis: function () {

      let filtered = []
      let added = []

      for(const row of this.data) {
        if (row.diagnosis.length > 0) {
          for (const diagnosis of row.diagnosis) {
            if (added.indexOf(diagnosis.diagnosis_id) === -1) {
              added.push(diagnosis.diagnosis_id)
              filtered.push(diagnosis.meta.diagnosis)
            }
          }
        }
      }

      return filtered

    },
    selectableTreatmentsituations: function () {

      let filtered = []
      let added = []

      for(const row of this.data) {
        if (row.treatmentsituations.length > 0) {
          for (const ts of row.treatmentsituations) {
            if (added.indexOf(ts.treatmentsituation_id) === -1) {
              added.push(ts.treatmentsituation_id)
              filtered.push(ts.meta.treatmentsituation)
            }
          }
        }
      }

      return filtered

    },
    selectableBiomarker: function () {

      let filtered = []
      let added = []

      for(const row of this.data) {
        if (row.biomarker.length > 0) {
          for (const biomarker of row.biomarker) {
            if (added.indexOf(biomarker.biomarker_id) === -1) {
              added.push(biomarker.biomarker_id)
              filtered.push(biomarker.meta.biomarker)
            }
          }
        }
      }

      return filtered

    }
  },
  watch: {
    data: function (val) {
      this.tableData = val
      this.filter.diagnosis = null
      this.filter.treatmentsituation = null
      this.filter.biomarker = null
    },
    'filter.diagnosis': function () {
      this.filterTableData()
    },
    'filter.treatmentsituation': function () {
      this.filterTableData()
    },
    'filter.biomarker': function () {
      this.filterTableData()
    }
  },
  methods: {
    ...mapActions('users', ['getUsersCro']),
    onRowDeleteConfirmed(row) {
      this.$emit('onRowDelete', row)
    },
    onRowClick(row) {
      if(this.rowClickable) {
        this.currentSurvey = row
        this.showSurveyForm = true
      }
    },
    onRowRightClick(row) {
      if(this.rowExpandable) {
        this.currentTableRow = row
        this.$refs.surveyTable.setCurrentRow(row)
        this.$refs.surveyTable.toggleRowExpansion(row)
      }
    },
    onRowExpand(row) {
      this.currentTableRow = row
      this.$refs.surveyTable.setCurrentRow(row)
    },
    onSurveyAddPatientClick() {
      this.currentSurveyPatient = {
        id: 0
      }
      this.showSurveyPatientForm = true
    },
    onSelectPatient(patient) {
      this.showSelectPatientForm = false
      this.openSurveyPatientForm(patient)
    },
    onAfterSaveSurveyPatientForm() {
      this.currentSurveyPatient = null
      this.showSurveyPatientForm = false
      this.$emit("after-save")
    },
    openSurveyPatientForm(surveyPatient) {
      this.currentSurveyPatient = surveyPatient
      this.showSurveyPatientForm = true
    },
    openSelectPatientForm(survey) {
      this.showSelectPatientForm = true
      this.lastClickedSurvey = survey
    },
    filterTableData() {

      let filteredTableData = []

      for(const row of this.data) {

        let add = true

        if(this.filter.diagnosis > 0 && (!row.diagnosis || !_.find(row.diagnosis, (o) => o.diagnosis_id == this.filter.diagnosis))) {
          add = false
        }

        if(this.filter.treatmentsituation > 0 && (!row.treatmentsituations || !_.find(row.treatmentsituations, (o) => o.treatmentsituation_id == this.filter.treatmentsituation))) {
          add = false
        }

        if(this.filter.biomarker > 0 && (!row.biomarker || !_.find(row.biomarker, (o) => o.biomarker_id == this.filter.biomarker))) {
          add = false
        }

        if(add) filteredTableData.push(row)

      }

      this.tableData = filteredTableData


    },
    async openCroLink(survey) {

      if(window.gui) window.gui.send('open-link', survey.cro.ecrf_link)
      else window.open(survey.cro.ecrf_link, "_blank");

      const user = await this.$auth.fetch()
      if(!user.data) return
      const result = await this.getUsersCro({ user_id: user.data.id, cro_id: survey.cro.id})
      this.currentCroData = _.find(result, (o) => o.cro_id == survey.cro.id)
      if(this.currentCroData) {
        this.showCroData = true
      }

    },
    copyCroUsername() {
      navigator.clipboard.writeText(this.currentCroData.username)
      this.$notify({
        message: 'Benutzername wurde in die Zwischenablage kopiert',
        type: 'success',
        position: 'bottom-left'
      });
    },
    copyCroPassword() {
      navigator.clipboard.writeText(this.currentCroData.password)
      this.$notify({
        message: 'Passwort wurde in die Zwischenablage kopiert',
        type: 'success',
        position: 'bottom-left'
      });
    }
}
}
</script>
<style scoped>
</style>
